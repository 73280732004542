// src/components/Layout.tsx

import React, { ReactNode } from "react";
import { Header } from "./common";
import Footer from "./common/Footer";
import { Helmet } from "react-helmet";

interface LayoutProps {
  location: Location;
  title: string;
  description?: string; // Optional description prop
  children: ReactNode;
}


const Layout: React.FC<LayoutProps> = ({ location, children }) => {

  // Logic for determining if it's the root path can be adjusted as needed
  const rootPath = "/";
  const isRootPath = location.pathname === rootPath;

  return (
    <>
      <Helmet>
        <title>SchemaJS blog</title>
        <meta name="description" content="The NoSQL, blazingly fast database built on top of V8 for developers who breath JavaScript." />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <div className={`relative antialiased flex flex-col`}>
        <Header />
        <main className="flex-1 flex flex-col min-h-screen bg-skin-base mb-12 px-8 lg:px-24 py-2 md:py-8 overflow-y-auto">
          {children}
        </main>
        <Footer />
      </div>

    </>
  );
};

export default Layout;
